import { useParams } from "react-router-dom";
import UsePocketbase from "../composables/UsePocketbase";

import React, { useState, useEffect } from "react";
import Card from "../components/Card";

function ApiPage() {
	const { api_id } = useParams();
	const { pocketbase } = UsePocketbase();

	const [api, setApi] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const items = await pocketbase.collection("apps").getOne(api_id, { expand: "methods,methods.headers,methods.params", sort: "+methods.order" });
				setApi(items);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [pocketbase, api_id]);

	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<div className={`card mb-4 shadow border-left-info`}>
						<div className="card-body">
							<h3 className="h3 mb-1 text-gray-800">{api.name}</h3>
							<div className="mb-4" dangerouslySetInnerHTML={{ __html: api.description }}></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				{api.expand?.methods
					.sort((a, b) => a.order - b.order)
					.map((item, i) => (
						<div className="col-lg-12" key={i}>
							<Card data={item} endpoint={api.endpoint} />
						</div>
					))}
			</div>
		</>
	);
}

export default ApiPage;
