function ExampleJson({ params }) {
	let y = {};
	params
		.sort((a, b) => a.key.localeCompare(b.key))
		.map((param, ix) => {
			let arrinarr = param.key.split(".");

			if (arrinarr.length === 1) {
				y[param.key] = param.datatype === "Object" ? JSON.parse(param.example) : param.example;
			} else {
				let ix = arrinarr[0];

				if (!y.hasOwnProperty(ix)) {
					y[ix] = {};
				}

				y[ix][arrinarr[1]] = param.example;
			}

			return param;
		});

	return <>{JSON.stringify(y, null, 4)}</>;
}

export default ExampleJson;
