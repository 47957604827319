import "../custom.css";

function TableParameters({ params }) {
	if (params) {
		params = params.sort((a, b) => b.required - a.required);
	}

	return (
		<div className="my-3">
			{params ? (
				<>
					<p>
						<strong>
							<i className="fas fa-terminal"></i> Parametros
						</strong>
					</p>
					<table className="table table-sm table-striped my-3 text-center">
						<thead>
							<tr>
								<th className="col-sm-2">Nombre</th>
								<th className="col-sm-2">Tipo de datos</th>
								<th className="col-sm-2">Opcional</th>
								<th className="col-sm-6">Descripción</th>
							</tr>
						</thead>
						<tbody>
							{params.map((header, ix) => (
								<tr key={ix}>
									<td className="text-gray-900">{header.key}</td>
									<td className="text-gray-900">{header.datatype}</td>
									<td className={header.required ? "text-danger" : "text-gray-900"}>{header.required ? "Requerido" : "Opcional"}</td>
									<td className="text-gray-900 capitalize">{header.description}</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			) : (
				<h5>Sin parametros</h5>
			)}
		</div>
	);
}

export default TableParameters;
