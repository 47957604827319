import "../custom.css";

function TableHeaders({ headers }) {
	if (headers) {
		headers = headers.sort((a, b) => a.key.localeCompare(b.key));
	}

	return (
		<div className="my-3">
			{headers ? (
				<>
					<p>
						<strong>
							<i className="fas fa-terminal"></i> Cabeceras
						</strong>
					</p>
					<table className="table table-sm table-striped my-3 text-center">
						<thead>
							<tr>
								<th className="col-sm-3">Llave</th>
								<th className="col-sm-3">Valor</th>
								<th className="col-sm-6">Notas</th>
							</tr>
						</thead>
						<tbody>
							{headers.map((header, ix) => (
								<tr key={ix}>
									<td className="text-gray-900">{header.key}</td>
									<td className="text-gray-900">{header.value}</td>
									<td className="text-gray-900 capitalize">{header.description}</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			) : (
				<h5>Sin cabeceras</h5>
			)}
		</div>
	);
}

export default TableHeaders;
