import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import UsePocketbase from "../composables/UsePocketbase";
import CardCollapsable from "../components/CardCollapsable";
import TableHeaders from "../components/TableHeaders";
import TableParameters from "../components/TableParameters";
import ExampleJson from "../components/ExampleJson";
import ExampleQuery from "../components/ExampleQuery";
import ExampleParams from "../components/ExampleParams";

function MethodPage() {
	const { method_id } = useParams();
	const { pocketbase } = UsePocketbase();

	const [method, setMethod] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				var items = await pocketbase.collection("methods").getOne(method_id, {
					expand: "app,params,headers,responses",
				});

				setMethod(items);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [pocketbase, method_id]);

	const verbClassMap = {
		GET: "success",
		POST: "primary",
		PUT: "warning",
		PATCH: "warning",
		DELETE: "danger",
	};

	return (
		<>
			<div className="row">
				<div className="col-lg-8">
					<div className={`card mb-4 shadow border-left-${verbClassMap[method.verb] || ""}`}>
						<div className="card-body">
							<Link to={`/app/method/${method.id}`} className={`btn btn-icon-split mb-4 btn-${verbClassMap[method.verb] || ""}`}>
								<span className="icon text-white">
									<strong>{method.verb}</strong>
								</span>
								<span className="text">{method.expand?.app.endpoint + "" + method.uri}</span>
							</Link>

							<TableHeaders headers={method.expand?.headers} />
							<TableParameters params={method.expand?.params} />
							<div className="my-3">
								<p>
									<strong>
										<i className="fas fa-terminal"></i> Ejemplo
									</strong>
								</p>
								<pre className="bg-dark text-white p-4">
									{method.type === "body" ? <ExampleJson params={method.expand?.params} /> : null}
									{method.type === "querystring" ? <ExampleQuery params={method.expand?.params} /> : null}
									{method.type === "param" ? <ExampleParams params={method.expand?.params} endpoint={method.expand?.app.endpoint + "" + method.uri} /> : null}
								</pre>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-4">
					{method.expand?.responses
						?.sort((a, b) => a.status_code - b.status_code)
						.map((response, ix) => (
							<CardCollapsable response={response} key={ix} />
						))}
				</div>
			</div>
		</>
	);
}

export default MethodPage;
