import { Link } from "react-router-dom";
import React, { useState } from "react";

function CardCollapsable({ response }) {
	const isSuccess = response.status_code >= 200 && response.status_code <= 299;
	const borderColorClass = isSuccess ? "border-left-success" : "border-left-danger";
	const textColorClass = isSuccess ? "text-success" : "text-danger";

	const [open, setOPen] = useState(false);

	const toggle = () => {
		setOPen(!open);
	};

	return (
		<div className="row">
			<div className="col-md-12">
				<div className={`card mb-4 ${borderColorClass}`}>
					<Link className={`card-header ${textColorClass}`} onClick={toggle}>
						<h6 style={{ fontSize: "0.75rem", fontWeight: 500 }}>
							<strong>{response.status_code}</strong> - <span className="text">{response.description}</span>
						</h6>
					</Link>
					<div className={`collapse ${open ? "show" : ""}`}>
						<div className="card-body api-response">
							<pre className="cut-api_response">{JSON.stringify(response.result, null, 2)}</pre>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CardCollapsable;
