// Using ES modules (default)
import PocketBase from "pocketbase";

const pocketbase = new PocketBase("https://docs.contacta.mx/api/");

pocketbase.autoCancellation(false);
// pocketbase.authStore.onChange( (token, model) => {

//     const { user } = useAuthUser();
//     user.value = token ? { token, model } : null;
// })

export default function UsePocketbase() {
	return { pocketbase };
}
