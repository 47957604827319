function ExampleQuery({ params }) {
	var str = [];
	params.map((param, ix) => {
		str.push(encodeURIComponent(param.key) + "=" + encodeURIComponent(param.example));
		return param;
	});

	return <>{str.join("&")}</>;
}

export default ExampleQuery;
