function HomePage() {
	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<div className="card shadow mb-4">
						<div className="card-header bg-primary text-white py-3">
							<h6 className="m-0 font-weight-bold">Política de Uso de APIs y Responsabilidades del Proveedor</h6>
						</div>

						<div className="card-body">
							<div className="row">
								<div className="col-lg-4">
									<div className="text-center">
										<img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{ width: "25rem" }} src="/img/home_2.jpeg" alt="Developer" />
									</div>
								</div>

								<div className="col-lg-8 px-5">
									<p>Por favor, lea atentamente los términos y condiciones de uso de las APIs proporcionadas por Contacta, en adelante "el Proveedor". Al acceder o utilizar nuestras APIs, usted acepta cumplir con los siguientes términos y condiciones:</p>
									<ul>
										<li>El uso de las APIs proporcionadas por el Proveedor está sujeto a los siguientes términos y condiciones. Estos términos y condiciones pueden actualizarse periódicamente sin previo aviso. El Proveedor se reserva el derecho de suspender o revocar el acceso a las APIs en cualquier momento y por cualquier motivo sin responsabilidad alguna.</li>

										<li>Las APIs se proporcionan con el propósito de facilitar el acceso a ciertos servicios y datos. El uso de las APIs debe ser coherente con los términos de servicio de cada API específica y con las políticas del Proveedor. El uso de las APIs con fines ilegales, maliciosos o que violen la privacidad y seguridad de terceros está estrictamente prohibido.</li>

										<li>El Proveedor no se hace responsable de los daños directos, indirectos, incidentales o consecuentes derivados del uso de las APIs. El uso de las APIs es bajo su propio riesgo. El Proveedor no garantiza la disponibilidad continua, la precisión o la confiabilidad de las APIs y puede interrumpir o limitar el acceso en cualquier momento.</li>

										<li>Usted es responsable de garantizar la seguridad de las credenciales y tokens de acceso proporcionados por el Proveedor para utilizar las APIs. Cualquier uso no autorizado de sus credenciales es su responsabilidad y debe informarse de inmediato al Proveedor.</li>

										<li>Al utilizar nuestras APIs, usted acepta estos términos y condiciones y se compromete a cumplir con todas las leyes y regulaciones aplicables. Si no está de acuerdo con estos términos, le rogamos que no utilice nuestras APIs.</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default HomePage;
