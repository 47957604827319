import UsePocketbase from "../composables/UsePocketbase";

import "../custom.css";

import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

function LeftMenu() {
	const [menus, setMenus] = useState([]);

	const fetchData = async () => {
		try {
			const { pocketbase } = UsePocketbase();

			var items = await pocketbase.collection("apps").getFullList({ expand: "methods", sort: "+order" });

			items = items.map((item) => {
				item.expand?.methods.sort((a, b) => a.order - b.order);
				return item;
			});

			setMenus(items);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
			<Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
				<div className="sidebar-brand-text mx-3 icon-large">
					<img src="/img/logo.png" alt="logo" />
				</div>
			</Link>

			<hr className="sidebar-divider my-0" />

			<li className="nav-item active">
				<Link className="nav-link" to="/">
					<i className="fas fa-fw fa-tachometer-alt"></i>
					<span>Dashboard</span>
				</Link>
			</li>

			<hr className="sidebar-divider" />

			{menus.map((app, i) => (
				<div key={i}>
					<Link className="nav-link" to={"/app/" + app.id}>
						<div className="sidebar-heading">{app.name}</div>
					</Link>

					{app.expand?.methods.map((method, ix) => (
						<li className="nav-item" key={method.id}>
							<Link className="nav-link" to={"/app/method/" + method.id}>
								<i className={`fa fa-terminal`}></i>
								<span>
									{method.verb} - {method.name}
								</span>
							</Link>
						</li>
					))}

					{menus.length - 1 > i ? <hr className="sidebar-divider" /> : null}
				</div>
			))}
		</ul>
	);
}

export default LeftMenu;
