function ExampleParams({ params, endpoint }) {
	let replace_endpoint = endpoint;

	params?.forEach((element) => {
		let regex = new RegExp(`{${element.key}}`);
		replace_endpoint = replace_endpoint.replace(regex, element.example);
	});

	return <>{replace_endpoint}</>;
}

export default ExampleParams;
