import { Link } from "react-router-dom";

function Header() {
	return (
		<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
			<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
				<i className="fa fa-bars"></i>
			</button>

			<ul className="navbar-nav ml-auto">
				<li className="nav-item ">
					<Link className="nav-link" to="mailto:soporte@contacta.mx">
						<span className="mr-2 d-none d-lg-inline text-gray-600 small">
							<i className="fa fa-globe"></i> contacta.mx
						</span>
					</Link>
				</li>
				<div className="topbar-divider d-none d-sm-block"></div>

				<li className="nav-item ">
					<Link className="nav-link" to="mailto:soporte@contacta.mx">
						<span className="mr-2 d-none d-lg-inline text-gray-600 small">
							<i className="fa fa-at"></i> soporte@contacta.mx
						</span>
					</Link>
				</li>
			</ul>
		</nav>
	);
}

export default Header;
