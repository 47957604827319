// import './App.css';
import HomePage from "./pages/HomePage";
import ApiPage from "./pages/ApiPage";
import MethodPage from "./pages/MethodPage";

import LeftMenu from "./components/LeftMenu";
import Header from "./components/Header";

import React from "react";
import { Routes, Route } from "react-router-dom";

function App() {
	return (
		<div id="wrapper">
			<LeftMenu />
			<div id="content-wrapper" className="d-flex flex-column">
				<div id="content">
					<Header />
					<div className="container-fluid">
						<Routes>
							<Route path="/" element={<HomePage />} />

							<Route path="/app/:api_id" element={<ApiPage />} />
							<Route path="/app/method/:method_id" element={<MethodPage />} />
						</Routes>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
