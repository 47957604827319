import { Link } from "react-router-dom";

import "../custom.css";
import TableHeaders from "./TableHeaders";
import TableParameters from "./TableParameters";

function Card({ data, endpoint }) {
	return (
		<>
			{data.verb ? (
				<div
					className={`card mb-4 shadow 
                ${data.verb === "GET" ? "border-left-success" : ""} 
                ${data.verb === "POST" ? "border-left-primary" : ""} 
                ${data.verb === "PUT" ? "border-left-warning" : ""} 
                ${data.verb === "PATCH" ? "border-left-warning" : ""} 
                ${data.verb === "DELETE" ? "border-left-danger" : ""}`}
				>
					<div className="card-body">
						<Link
							to={"/app/method/" + data.id}
							className={`btn btn-icon-split
                        ${data.verb === "GET" ? "btn-success" : ""} 
                        ${data.verb === "POST" ? "btn-primary" : ""} 
                        ${data.verb === "PUT" ? "btn-warning" : ""} 
                        ${data.verb === "PATCH" ? "btn-warning" : ""} 
                        ${data.verb === "DELETE" ? "btn-danger" : ""}`}
						>
							<span className="icon text-white">
								<strong>{data.verb}</strong>
							</span>
							<span className="text">{endpoint + "" + data.uri}</span>
						</Link>

						<TableHeaders headers={data.expand?.headers} />
						<TableParameters params={data.expand?.params} />
					</div>
				</div>
			) : (
				<h3>Sin data</h3>
			)}
		</>
	);
}

export default Card;
